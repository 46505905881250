<template>
  <div class="subscription-box open">
    <div class="subscription-box__top">
      <div>
        <div class="subscription-box__image">
          <img :src="addressBook.logo_url" :alt="addressBook.name" />
        </div>
        <div>
          <p class="title">{{ addressBook.name }}</p>
          <p class="subtitle">{{ addressBook.description[$i18n.locale] }}</p>
        </div>
      </div>
      <SwitchComp
        @input="toggleIsOpen"
        :presetValue="isSwitchedOn"
        :key="isSwitchedOn"
      />
    </div>
    <div v-if="isSubscribeOpen" class="subscription-box__collapsible">
      <InputText
        :isDisabled="true"
        :label="$t('subscription.placeholderEmail')"
        :value="userEmail"
        class="spacing-bottom"
      />
      <InputText
        :isDisabled="true"
        :label="$t('subscription.placeholderFirstName')"
        :value="userFirstName"
        class="spacing-bottom"
      />
      <InputText
        :isDisabled="true"
        :label="$t('subscription.placeholderCountry')"
        :value="userCountry"
        class="spacing-bottom"
      />
      <span class="hint-text spacing-bottom">
        {{ $t("subscription.subscribeText1", { book: addressBook.name }) }}
      </span>
      <Button
        @click="onSubscribe"
        :text="$t('subscription.subscribeButton')"
        isSmall="true"
        class="spacing-bottom"
      />
      <span
        class="hint-text spacing-bottom"
        v-html="$t('subscription.subscribeText2')"
      >
      </span>
    </div>

    <div v-if="isSend" class="subscription-box__collapsible">
      <p class="subtitle bold">
        {{ $t("subscription.onSendTitle") }}
      </p>
      <span class="subtitle spacing-bottom">
        {{ $t("subscription.onSendText") }}
      </span>
    </div>
    <div v-else-if="isUnsubscribeOpen" class="subscription-box__collapsible">
      <p class="subtitle bold">
        {{ $t("subscription.unsubscribeTitle") }}
      </p>
      <span class="hint-text spacing-bottom">
        {{ $t("subscription.unsubscribeText1") }}
      </span>
      <Button
        @click="onUnsubscribe"
        :text="$t('subscription.unsubscribeButton')"
        isSmall="true"
        class="spacing-bottom"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import SwitchComp from './SwitchComp.vue';
import InputText from './InputText.vue';
import Button from './Button.vue';
import { AddressBook } from '../types';

@Component({
  components: {
    SwitchComp,
    InputText,
    Button,
  },
})
export default class SubscriptionBox extends Vue {
  @Prop() addressBook!: AddressBook;
  @Prop() isSwitchedOn!: boolean;

  @Getter userEmail!: string;
  @Getter userFirstName!: string;
  @Getter userCountry!: string;

  isEditOpen = false;
  isSend = false;

  toggleIsOpen() {
    this.isEditOpen = !this.isEditOpen;
  }

  get isSubscribeOpen(): boolean {
    return this.isEditOpen === true && this.isSwitchedOn === false;
  }

  get isUnsubscribeOpen(): boolean {
    return this.isEditOpen === true && this.isSwitchedOn === true;
  }

  onSubscribe() {
    this.$emit('onSubscribe');
    this.isSend = true;
    this.isEditOpen = false;
  }

  onUnsubscribe() {
    this.$emit('onUnsubscribe');
    this.isEditOpen = false;
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables.scss";

.subscription-box {
  padding: 2rem;
  margin-bottom: 2rem;

  &__top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3rem;
    @media only screen and (min-width: $bp-xl) {
      margin-bottom: 0;
    }
    > div {
      flex-direction: column;
      display: flex;
      align-items: start;
      @media only screen and (min-width: $bp-xl) {
        flex-direction: row;
        align-items: center;
      }
    }
  }

  &__image {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 3rem;
    height: 18rem;
    margin-bottom: 2rem;

    @media only screen and (min-width: $bp-xl) {
      margin-bottom: 0rem;
      margin-right: 3rem;
    }

    img {
      width: 20rem;
    }
  }

  .title {
    font-size: $font-size-5;
    font-weight: bold;
    margin-bottom: 2rem;
  }

  .subtitle {
    color: $grey-12;
    display: block;
    font-size: $font-size-1;

    &.bold {
      color: $black;
      font-weight: bold;
    }
  }

  .hint-text {
    color: $grey-12;
    display: block;
    font-size: $font-size-0;
  }

  &__collapsible {
    overflow: hidden;
    height: 0;
    @media only screen and (min-width: $bp-xl) {
      padding-left: 29rem;
    }
    .title {
      margin-bottom: 2rem;
    }
    .spacing-bottom {
      margin-bottom: 2rem;
    }
  }

  &.open {
    background: $grey-3;

    .subscription-box__collapsible {
      height: 100%;
    }
  }

  &__text {
    font-size: $font-size-2;
    color: $black;
    max-width: 28rem;
  }
}
</style>
