<template>
  <div
    @click="$emit('click')"
    class="genre-tile"
    :style="{ background: isActive ? color : '#c0c0c0' }"
    :class="{ 'genre-tile--active': isActive }"
  >
    <span>{{genre}}</span>
    <img
      :src="image"
      alt=""
    >
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class GenreTile extends Vue {
  @Prop() genre!: string
  @Prop() color!: string
  @Prop() image!: string
  @Prop() isActive!: boolean
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables.scss";

.genre-tile {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  cursor: pointer;

  span {
    font-size: $font-size-0;
    font-weight: bold;
    position: absolute;
    color: $white;
    bottom: 1rem;
    left: 1rem;
    z-index: 1;
    word-break: break-all;
    @media only screen and (min-width: $bp-l) {
      font-size: $font-size-4;
    }
  }

  img {
    filter: grayscale(100%);
    object-fit: cover;
    display: block;
    opacity: 0.45;
  }

  &--active {
    img {
      filter: none;
    }
  }
}
</style>
