<template>
  <div class="artist-tile" :class="{ plus: isPlus }">
    <img v-if="image" class="artist-tile__image" :src="image" :alt="name" />
    <div v-if="!image" class="artist-tile__image"></div>
    <span class="artist-tile__name">{{ name }}</span>
    <svg
      :class="{ 'is-close-open': isCloseOpen }"
      @click="$emit('onArtist')"
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="26"
      fill="none"
    >
      <circle cx="12.5" cy="13" r="12" fill="#000" stroke="#282631" />
      <path
        d="M8.843 9.485l7.03 7.03M8.843 16.622l7.03-7.031"
        stroke="#fff"
        stroke-linecap="round"
      />
    </svg>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Headline extends Vue {
  @Prop() image!: string
  @Prop() isPlus!: boolean
  @Prop() isCloseOpen!: boolean
  @Prop() name!: string
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables.scss";

.artist-tile {
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 5rem;
  padding-right: 1rem;
  cursor: pointer;
  position: relative;

  &.plus {
    background: $white;
    svg {
      opacity: 1;
      transform: rotate(45deg);
    }
    &:hover {
      background: $white;
    }
  }

  svg {
    opacity: 0;
    right: 1rem;
    position: absolute;

    &.is-close-open {
      opacity: 1;
    }
  }

  &:hover {
    background: #f0f0f0;

    svg {
      opacity: 1;
    }
  }
  &__image {
    height: 5rem;
    width: 5rem;
    object-fit: cover;
    border-radius: 5rem;
    margin-right: 1rem;
    background: #c4c4c4;
    border: 2px solid black;
  }
  &__name {
    /* line-height: 5rem; */
    font-size: $font-size-2;
    font-weight: bold;
    color: $black;
  }
}
</style>
