<template>
  <div
    class="connect-block container-small"
    v-if="userIsSpotifyConnected"
  >

    <span class="connect-block__title">
      {{$t('connected.title', { name: userSpotifyName })}}
    </span>
    <div>
      <span class="connect-block__text">
        {{$t('connected.text')}}
      </span>
      <div>
        <Button
          @click="$emit('onRefresh')"
          :isSpotify="true"
          :isFullWidth="true"
          :text="$t('connected.refresh')"
        />
        <Button
          @click="$emit('onDisconnect')"
          :isSpotify="true"
          :isFullWidth="true"
          :text="$t('connected.disconnect')"
        />
      </div>
    </div>
  </div>

  <div
    class="connect-block container-small"
    v-else
  >
    <span class="connect-block__title">
      {{ connectNumber === 1 ? $t('connect.title1') : $t('connect.title2') }}
    </span>
    <div>
      <span class="connect-block__text">
      {{ connectNumber === 1 ? $t('connect.text1') : $t('connect.text2') }}
      </span>
      <div>
        <Button
          @click="$emit('onLogin', 'spotify')"
          :isSpotify="true"
          :isFullWidth="true"
          :text="$t('connect.connect')"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import Button from './Button.vue';

@Component({
  components: {
    Button,
  },
})
export default class ConnectBlock extends Vue {
  @Prop() connectNumber!: number

  @Getter userSpotifyName!: string
  @Getter userIsSpotifyConnected!: boolean
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables.scss";

.connect-block {
  background: $grey-3;
  padding: 2rem 4rem 4rem 2rem;
  margin-bottom: 3rem;
  width: 100%;
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: $bp-xl) {
    width: unset;
    display: block;
  }

  &__title {
    font-size: $font-size-5;
    font-weight: bold;
    margin-bottom: 2rem;
    display: block;
  }

  &__text {
    font-size: $font-size-3;
    color: $grey-1;
    width: 100%;
    max-width: 40rem;
    margin-bottom: 2rem;
  }

  > div {
    display: flex;
    flex-direction: column;

    @media only screen and (min-width: $bp-xl) {
      flex-direction: row;
      justify-content: space-between;
    }

    .button {
      margin-bottom: 2rem;
    }
  }
}
</style>
