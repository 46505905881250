<template>
  <div class="header">
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="46"
        height="49"
        fill="none"
      >
        <path
          d="M43.575.345v8.023a3.647 3.647 0 01-3.647 3.647H5.647A3.647 3.647 0 012 8.368V.345M2 48.484V40.46a3.647 3.647 0 013.647-3.647h34.28a3.647 3.647 0 013.648 3.647v8.023"
          stroke="#B2B2BB"
          stroke-width="3.616"
        />
        <path
          d="M43.575 33.167V15.956c0-3.178-3.78-4.835-6.118-2.683L25.258 24.51a3.647 3.647 0 01-4.941 0l-12.2-11.236C5.782 11.121 2 12.778 2 15.956v17.211a3.647 3.647 0 003.647 3.647h34.28a3.647 3.647 0 003.648-3.647z"
          stroke="#000"
          stroke-width="3.616"
        />
      </svg>
      <h1 class="header__title">{{ $t('header.title') }}</h1>
    </div>
    <SwitchComp
      :isLanguage="true"
      :languages="languages"
      @onLanguage="onLanguage"
    />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import SwitchComp from './SwitchComp.vue';
import { LOCALES } from '../locale';

@Component({
  components: {
    SwitchComp,
  },
})
export default class Button extends Vue {
  get languages(): { id: string; label: string }[] {
    return LOCALES.map((id) => ({ id, label: this.$t(`locales.${id}`).toString() }));
  }

  onLanguage(id: string) {
    this.$i18n.locale = id;
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables.scss";

.header {
  background: #cfcfcf;
  padding: 1.6rem 1rem;
  display: flex;
  justify-content: space-between;
  margin-bottom: 6rem;
  > div {
    align-items: center;
    display: flex;
  }
  svg {
    margin-right: 2rem;
  }
  &__title {
    font-weight: bold;
    font-size: $font-size-2;
  }
}
</style>
