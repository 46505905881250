<template>
  <div class="genre-block container-small">
    <div>
      <Headline :headline="$t('genres.title')" />
      <p class="genre-block__counter">
        <span class="counter">{{ genres.length === userGenres.length ? $t('genres.subtitleAll') : userGenres.length }} </span>
        <span class="label">{{ userGenres.length > 1 ? $t('genres.subtitle') : $t('genres.subtitleSingle') }}</span>
      </p>
    </div>

    <ul>
      <li
        v-for="(genre, index) in genres"
        :key="index"
      >
        <GenreTile
          @click="toggleGenre(genre.id)"
          :genre="$t(`genres.${genre.translationId}`)"
          :image="genre.imageUrl"
          :color="genre.color"
          :isActive="genre.isActive"
        />
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import Headline from './Headline.vue';
import GenreTile from './GenreTile.vue';
import { GENRES } from '../genres';

@Component({
  components: {
    Headline,
    GenreTile,
  },
})

export default class GenreBlock extends Vue {
  @Getter isUserLoaded!: boolean

  get genres() {
    return GENRES.map((g) => ({
      ...g,
      isActive: this.userGenres.includes(g.id),
    }));
  }

  get userGenres() {
    return this.$store.getters.userGenres;
  }
  set userGenres(val: string[]) {
    this.$store.commit('SET_USER_GENRES', val);
  }

  toggleGenre(genreId: string) {
    if (this.userGenres.includes(genreId)) {
      this.userGenres = this.userGenres.filter((g) => g !== genreId);

      return;
    }

    this.userGenres = [...this.userGenres, genreId];
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables.scss";

.genre-block {
  margin-bottom: 3rem;

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    @media only screen and (min-width: $bp-xl) {
      flex-direction: row;
    }
  }
  &__counter {
    margin-bottom: 4rem;
    font-weight: bold;
    font-size: $font-size-3;
    .counter {
      color: $black;
    }
    .label {
      color: $grey-2;
    }
  }
  ul {
    display: grid;
    gap: 0.6rem;
    grid-template-areas: ". . .";

    @media only screen and (min-width: $bp-xl) {
      gap: 1rem;
      grid-template-areas: ". . . .";
    }
  }
}
</style>
