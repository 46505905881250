<template>
  <div class="subscriptions-block container-small">
    <Headline :headline="$t('subscription.title')" />
    <ul>
      <li
        v-for="(addressBook, index) in addressBooks"
        :key="index"
      >
        <SubscriptionBox
          :addressBook="addressBook"
          :isSwitchedOn="getIsSwitchedOn(addressBook.id)"
          @onSubscribe="onSubscribe(addressBook)"
          @onUnsubscribe="onUnsubscribe(addressBook)"
        />
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter, Mutation } from 'vuex-class';
import { AddressBook } from '../types';
import Headline from './Headline.vue';
import SubscriptionBox from './SubscriptionBox.vue';

@Component({
  components: {
    Headline,
    SubscriptionBox,
  },
})
export default class SubscriptionsBlock extends Vue {
  @Getter userAddressBooks!: AddressBook[]
  @Getter addressBooks!: AddressBook[]

  @Action('LOAD_ADDRESS_BOOKS') load!: () => Promise<void>
  @Action('UPDATE_USER_ADDRESS_BOOKS') updateUser!: () => Promise<void>

  @Mutation('SET_USER_ADDRESS_BOOKS') setAddressBooks!: (data: AddressBook[]) => Promise<void>

  getIsSwitchedOn(addressBookId: string): boolean {
    return !!this.userAddressBooks.find((a) => a.id === addressBookId);
  }

  async onSubscribe(addressBook: AddressBook) {
    await this.setAddressBooks([
      ...this.userAddressBooks,
      addressBook,
    ]);
    await this.updateUser();
  }

  async onUnsubscribe(addressBook: AddressBook) {
    await this.setAddressBooks([
      ...this.userAddressBooks.filter((ab) => ab.id !== addressBook.id),
    ]);
    await this.updateUser();
  }

  async created() {
    await this.load();
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables.scss";

.subscriptions-block {
  margin-bottom: 3rem;

  ul {
    width: 110%;
    margin-left: -5%;

    @media only screen and (min-width: $bp-xl) {
      width: 100%;
      margin-left: 0;
    }
  }
}
</style>
