import Vuex, {
  StoreOptions, MutationTree, GetterTree, ActionTree, ActionContext,
} from 'vuex';
import Vue from 'vue';
import rest from '@/rest';
import { AddressBook, Artist } from '@/types';

Vue.use(Vuex);

const WINDOW_BREAKPOINT = 1024;

export interface RootState {
  isLoading: boolean;
  isUserLoaded: boolean;
  errorMessage: string;
  userContactId: string;
  userEmail: string;
  userFirstName: string;
  userLastName: string;
  userPhone: string;
  userAddressLine1: string;
  userAddressLine2: string;
  userCity: string;
  userPostcode: string;
  userBirthDay: string;
  userBirthMonth: string;
  userCountry: string;
  userArtists: Artist[];
  userArtistsOriginal: Artist[];
  userLoginUrl: string;
  userGenres: string[];
  userGenresOriginal: string[];
  userAddressBooks: AddressBook[];
  userIsSpotifyConnected: boolean;
  userSpotifyToken: string;
  userSpotifyName: string;
  userEmailHash: string; // May be actual email or hashed email before user is loaded
  artistsSearch: Artist[];
  windowWidth: number;
  addressBooks: AddressBook[];
  geoLocation: string;
}

export const rootState: RootState = {
  isLoading: false,
  isUserLoaded: false,
  errorMessage: '',
  userContactId: '',
  userEmail: '',
  userFirstName: '',
  userLastName: '',
  userPhone: '',
  userAddressLine1: '',
  userAddressLine2: '',
  userCity: '',
  userPostcode: '',
  userBirthDay: '',
  userBirthMonth: '',
  userCountry: '',
  userLoginUrl: '',
  userArtists: [],
  userArtistsOriginal: [],
  userGenres: [],
  userGenresOriginal: [],
  userAddressBooks: [],
  userIsSpotifyConnected: false,
  userSpotifyToken: '',
  userSpotifyName: '',
  userEmailHash: '',
  artistsSearch: [],
  windowWidth: WINDOW_BREAKPOINT,
  addressBooks: [],
  geoLocation: '',
};

export const rootMutations: MutationTree<RootState> = {
  SET_LOADING(state: RootState, data: boolean) {
    state.isLoading = data;
  },
  SET_USER_LOADED(state: RootState, data: boolean) {
    state.isUserLoaded = data;
  },
  SET_ERROR_MESSAGE(state: RootState, data: string) {
    state.errorMessage = data;
  },
  SET_USER_CONTACT_ID(state: RootState, data: string) {
    state.userContactId = data;
  },
  SET_USER_EMAIL(state: RootState, data: string) {
    state.userEmail = data;
  },
  SET_USER_EMAIL_HASH(state: RootState, data: string) {
    state.userEmailHash = data;
  },
  SET_USER_FIRST_NAME(state: RootState, data: string) {
    state.userFirstName = data;
  },
  SET_USER_LAST_NAME(state: RootState, data: string) {
    state.userLastName = data;
  },
  SET_USER_PHONE(state: RootState, data: string) {
    state.userPhone = data;
  },
  SET_USER_ADDRESS_LINE_1(state: RootState, data: string) {
    state.userAddressLine1 = data;
  },
  SET_USER_ADDRESS_LINE_2(state: RootState, data: string) {
    state.userAddressLine2 = data;
  },
  SET_USER_CITY(state: RootState, data: string) {
    state.userCity = data;
  },
  SET_USER_POSTCODE(state: RootState, data: string) {
    state.userPostcode = data;
  },
  SET_USER_BIRTH_DAY(state: RootState, data: string) {
    state.userBirthDay = data;
  },
  SET_USER_BIRTH_MONTH(state: RootState, data: string) {
    state.userBirthMonth = data;
  },
  SET_USER_COUNTRY(state: RootState, data: string) {
    state.userCountry = data;
  },
  SET_USER_ARTISTS(state: RootState, data: Artist[]) {
    state.userArtists = data;
  },
  SET_USER_ARTISTS_ORIGINAL(state: RootState, data: Artist[]) {
    state.userArtistsOriginal = data;
  },
  SET_USER_ADDRESS_BOOKS(state: RootState, data: AddressBook[]) {
    state.userAddressBooks = data;
  },
  SET_USER_LOGIN_URL(state: RootState, data: string) {
    state.userLoginUrl = data;
  },
  SET_USER_GENRES(state: RootState, data: string[]) {
    state.userGenres = data;
  },
  SET_USER_GENRES_ORIGINAL(state: RootState, data: string[]) {
    state.userGenresOriginal = data;
  },
  SET_USER_IS_SPOTIFY_CONNECTED(state: RootState, data: boolean) {
    state.userIsSpotifyConnected = data;
  },
  SET_USER_SPOTIFY_TOKEN(state: RootState, data: string) {
    state.userSpotifyToken = data;
  },
  SET_USER_SPOTIFY_NAME(state: RootState, data: string) {
    state.userSpotifyName = data;
  },
  RESET_USER(state: RootState) {
    state.userGenres = state.userGenresOriginal;
    state.userArtists = state.userArtistsOriginal;
  },
  SET_ARTISTS_SEARCH(state: RootState, data: Artist[]) {
    state.artistsSearch = data;
  },
  SET_WINDOW_WIDTH(state: RootState, data: number) {
    state.windowWidth = data;
  },
  SET_ADDRESS_BOOKS(state: RootState, data: AddressBook[]) {
    state.addressBooks = data;
  },
  SET_GEO_LOCATION(state: RootState, data: string) {
    state.geoLocation = data;
  },
};

export const rootGetters: GetterTree<RootState, any> = {
  isLoading: (state: RootState) => state.isLoading,
  isUserLoaded: (state: RootState) => state.isUserLoaded,
  errorMessage: (state: RootState) => state.errorMessage,
  userContactId: (state: RootState) => state.userContactId,
  userEmail: (state: RootState) => state.userEmail,
  userFirstName: (state: RootState) => state.userFirstName,
  userLastName: (state: RootState) => state.userLastName,
  userPhone: (state: RootState) => state.userPhone,
  userAddressLine1: (state: RootState) => state.userAddressLine1,
  userAddressLine2: (state: RootState) => state.userAddressLine2,
  userCity: (state: RootState) => state.userCity,
  userPostcode: (state: RootState) => state.userPostcode,
  userBirthDay: (state: RootState) => state.userBirthDay,
  userBirthMonth: (state: RootState) => state.userBirthMonth,
  userCountry: (state: RootState) => state.userCountry,
  userArtists: (state: RootState) => state.userArtists,
  userArtistsOriginal: (state: RootState) => state.userArtistsOriginal,
  userLoginUrl: (state: RootState) => state.userLoginUrl,
  userAddressBooks: (state: RootState) => state.userAddressBooks,
  userGenres: (state: RootState) => state.userGenres,
  userGenresOriginal: (state: RootState) => state.userGenresOriginal,
  userSpotifyName: (state: RootState) => state.userSpotifyName,
  userIsSpotifyConnected: (state: RootState) => state.userIsSpotifyConnected,
  userChangesCount: (state: RootState) => [
    state.userGenres.filter((g) => !state.userGenresOriginal.includes(g)).length,
    state.userGenresOriginal.filter((g) => !state.userGenres.includes(g)).length,
    state.userArtists.filter((artist) => !state.userArtistsOriginal.find((oa) => oa.name === artist.name)).length,
    state.userArtistsOriginal.filter((artist) => !state.userArtists.find((a) => a.name === artist.name)).length,
  ].reduce((pv, cv) => pv + cv),
  artistsSearch: (state: RootState) => state.artistsSearch,
  isMobile: (state: RootState) => state.windowWidth < WINDOW_BREAKPOINT,
  addressBooks: (state: RootState) => state.addressBooks,
  geoLocation: (state: RootState) => state.geoLocation,
};

const rootActions: ActionTree<RootState, any> = {
  async LOAD_USER_LOGIN_URL(ctx: ActionContext<RootState, any>, data: { service: string; redirectUrl: string }) {
    const url = await rest.contacts.getUrlLogin(data.service, ctx.state.userContactId, ctx.state.userEmail, data.redirectUrl);

    ctx.commit('SET_USER_LOGIN_URL', url);
  },
  async LOAD_USER(ctx: ActionContext<RootState, any>) {
    ctx.commit('SET_LOADING', true);

    try {
      const user = await rest.contacts.loadUser(ctx.state.userContactId, ctx.state.userEmailHash);

      ctx.commit('SET_USER_EMAIL', user.email || '');
      ctx.commit('SET_USER_GENRES', user.genres || '');
      ctx.commit('SET_USER_GENRES_ORIGINAL', user.genres || []);
      ctx.commit('SET_USER_ARTISTS', user.artists || []);
      ctx.commit('SET_USER_ARTISTS_ORIGINAL', user.artists || []);
      ctx.commit('SET_USER_LAST_NAME', user.last_name || '');
      ctx.commit('SET_USER_FIRST_NAME', user.first_name || '');
      ctx.commit('SET_USER_FIRST_NAME', user.first_name || '');
      ctx.commit('SET_USER_PHONE', user.phone || '');
      ctx.commit('SET_USER_ADDRESS_LINE_1', user.address_line_1 || '');
      ctx.commit('SET_USER_ADDRESS_LINE_2', user.address_line_2 || '');
      ctx.commit('SET_USER_CITY', user.city || '');
      ctx.commit('SET_USER_POSTCODE', user.postal_code || '');
      ctx.commit('SET_USER_BIRTH_DAY', user.day_of_birth?.toString() || '');
      ctx.commit('SET_USER_BIRTH_MONTH', user.month_of_birth?.toString() || '');
      ctx.commit('SET_USER_COUNTRY', user.country || '');
      ctx.commit('SET_USER_ADDRESS_BOOKS', user.address_books || '');
      ctx.commit('SET_USER_SPOTIFY_NAME', user.spotify_display_name || '');
      ctx.commit('SET_USER_IS_SPOTIFY_CONNECTED', user.spotify_connected || false);
      ctx.commit('SET_USER_SPOTIFY_TOKEN', user.spotify_token);
      ctx.commit('SET_USER_LOADED', true);
    } catch (e) {
      ctx.commit('SET_ERROR_MESSAGE', e);
      throw (e);
    } finally {
      ctx.commit('SET_LOADING', false);
    }
  },
  async LOAD_USER_WITH_GENRE(ctx: ActionContext<RootState, any>, genre: string) {
    // Load the user with given genre saved. This is just an optimization to set a genre on startup
    // without having to load the user twice
    ctx.commit('SET_LOADING', true);
    try {
      const user = await rest.contacts.addUserGenres(ctx.state.userContactId, ctx.state.userEmailHash, [genre]);

      ctx.commit('SET_USER_EMAIL', user.email || '');
      ctx.commit('SET_USER_GENRES', user.genres || '');
      ctx.commit('SET_USER_GENRES_ORIGINAL', user.genres || []);
      ctx.commit('SET_USER_ARTISTS', user.artists || []);
      ctx.commit('SET_USER_ARTISTS_ORIGINAL', user.artists || []);
      ctx.commit('SET_USER_LAST_NAME', user.last_name || '');
      ctx.commit('SET_USER_FIRST_NAME', user.first_name || '');
      ctx.commit('SET_USER_FIRST_NAME', user.first_name || '');
      ctx.commit('SET_USER_PHONE', user.phone || '');
      ctx.commit('SET_USER_ADDRESS_LINE_1', user.address_line_1 || '');
      ctx.commit('SET_USER_ADDRESS_LINE_2', user.address_line_2 || '');
      ctx.commit('SET_USER_CITY', user.city || '');
      ctx.commit('SET_USER_POSTCODE', user.postal_code || '');
      ctx.commit('SET_USER_BIRTH_DAY', user.day_of_birth?.toString() || '');
      ctx.commit('SET_USER_BIRTH_MONTH', user.month_of_birth?.toString() || '');
      ctx.commit('SET_USER_COUNTRY', user.country || '');
      ctx.commit('SET_USER_ADDRESS_BOOKS', user.address_books || '');
      ctx.commit('SET_USER_SPOTIFY_NAME', user.spotify_display_name || '');
      ctx.commit('SET_USER_IS_SPOTIFY_CONNECTED', user.spotify_connected || false);
      ctx.commit('SET_USER_SPOTIFY_TOKEN', user.spotify_token);
      ctx.commit('SET_USER_LOADED', true);
    } catch (e) {
      ctx.commit('SET_ERROR_MESSAGE', e);
      throw (e);
    } finally {
      ctx.commit('SET_LOADING', false);
    }
  },
  async LOAD_USER_TOP(ctx: ActionContext<RootState, any>) {
    ctx.commit('SET_LOADING', true);

    try {
      const data = await rest.contacts.loadUserTop(ctx.state.userContactId, ctx.state.userEmailHash);
      const genres = (data.genres as string[]).filter((genre) => !ctx.state.userGenres.includes(genre));
      const artists = (data.artists as Artist[]).filter((artist) => !ctx.state.userArtists.find((ua) => ua.spotify_id === artist.spotify_id));

      ctx.commit('SET_USER_GENRES', [
        ...genres,
        ...ctx.state.userGenres,
      ]);
      ctx.commit('SET_USER_ARTISTS', [
        ...artists,
        ...ctx.state.userArtists,
      ]);
    } catch (e) {
      ctx.commit('SET_ERROR_MESSAGE', e);
      throw (e);
    } finally {
      ctx.commit('SET_LOADING', false);
    }
  },
  async UPDATE_USER(ctx: ActionContext<RootState, any>, data: { language: string }) {
    ctx.commit('SET_LOADING', true);

    try {
      const result = await rest.contacts.updateUser(
        ctx.state.userContactId,
        ctx.state.userEmailHash,
        {
          first_name: ctx.state.userFirstName,
          last_name: ctx.state.userLastName,
          phone: ctx.state.userPhone,
          address_line_1: ctx.state.userAddressLine1,
          address_line_2: ctx.state.userAddressLine2,
          city: ctx.state.userCity,
          postal_code: ctx.state.userPostcode,
          country: ctx.state.userCountry,
          spotify_connected: ctx.state.userIsSpotifyConnected,
          spotify_token: ctx.state.userSpotifyToken,
          genres: ctx.state.userGenres,
          artists: ctx.state.userArtists,
          address_books: ctx.state.userAddressBooks,
          month_of_birth: parseInt(ctx.state.userBirthMonth, 10) || null,
          day_of_birth: parseInt(ctx.state.userBirthDay, 10) || null,
          form_language: data.language,
        },
      );

      ctx.commit('SET_USER_GENRES_ORIGINAL', ctx.state.userGenres);
      ctx.commit('SET_USER_ARTISTS_ORIGINAL', ctx.state.userArtists);
      ctx.commit('SET_USER_SPOTIFY_TOKEN', result.spotify_token || '');
      ctx.commit('SET_USER_SPOTIFY_NAME', result.spotify_display_name || '');
      ctx.commit('SET_USER_IS_SPOTIFY_CONNECTED', result.spotify_connected);
    } catch (e) {
      ctx.commit('SET_ERROR_MESSAGE', e);
      throw (e);
    } finally {
      ctx.commit('SET_LOADING', false);
    }
  },
  async UPDATE_USER_ADDRESS_BOOKS(ctx: ActionContext<RootState, any>) {
    ctx.commit('SET_LOADING', true);

    try {
      const result = await rest.contacts.updateUserAddressbooks(
        ctx.state.userContactId,
        ctx.state.userEmailHash,
        ctx.state.userAddressBooks,
      );
      ctx.commit('SET_USER_SPOTIFY_TOKEN', result.spotify_token || '');
      ctx.commit('SET_USER_SPOTIFY_NAME', result.spotify_display_name || '');
      ctx.commit('SET_USER_IS_SPOTIFY_CONNECTED', result.spotify_connected);
    } catch (e) {
      ctx.commit('SET_ERROR_MESSAGE', e);
      throw (e);
    } finally {
      ctx.commit('SET_LOADING', false);
    }
  },
  async USER_DISCONNECT(ctx: ActionContext<RootState, any>) {
    ctx.commit('SET_LOADING', true);

    try {
      await rest.contacts.updateUser(
        ctx.state.userContactId,
        ctx.state.userEmailHash,
        { spotify_token: '' },
      );
      ctx.commit('SET_USER_SPOTIFY_TOKEN', '');
      ctx.commit('SET_USER_SPOTIFY_NAME', '');
      ctx.commit('SET_USER_IS_SPOTIFY_CONNECTED', false);
    } catch (e) {
      ctx.commit('SET_ERROR_MESSAGE', e);
      throw (e);
    } finally {
      ctx.commit('SET_LOADING', false);
    }
  },
  async SEARCH_ARTISTS(ctx: ActionContext<RootState, any>, p: { query: string }) {
    try {
      const data = await rest.artists.search(p.query);

      ctx.commit('SET_ARTISTS_SEARCH', data.items);
    } catch (e) {
      ctx.commit('SET_ERROR_MESSAGE', e);
      throw (e);
    }
  },
  async LOAD_ADDRESS_BOOKS(ctx: ActionContext<RootState, any>) {
    try {
      const data = await rest.addressBooks.get();

      ctx.commit('SET_ADDRESS_BOOKS', data.items);
    } catch (e) {
      ctx.commit('SET_ERROR_MESSAGE', e);
      throw (e);
    }
  },
  async LOAD_GEO_LOCATION(ctx: ActionContext<RootState, any>) {
    try {
      const data: { country_code: string } = await rest.geo.get();

      ctx.commit('SET_GEO_LOCATION', data.country_code.toLocaleLowerCase());
    } catch (e) {
      ctx.commit('SET_ERROR_MESSAGE', e);
      throw (e);
    }
  },
};

export const storeOptions: StoreOptions<RootState> = {
  state: rootState,
  mutations: rootMutations,
  getters: rootGetters,
  actions: rootActions,
  modules: {
  },
};

export default () => (new Vuex.Store(storeOptions));
