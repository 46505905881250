<template>
  <div class="headline-wrapper">
    <div class="headline">{{headline}}</div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Headline extends Vue {
  @Prop() headline!: string
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables.scss";

.headline {
  font-weight: bold;
  margin-bottom: 4rem;
  color: $black;
  font-size: $font-size-6;

  @media only screen and (min-width : $bp-xl) {
    font-size: $font-size-7;
  }
}
</style>
