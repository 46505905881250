<template>
  <div
    class="input-text"
    :class="{ 'input-text--disabled': isDisabled }"
  >
    <label>{{label}}</label>
    <input
      type="text"
      :value="value"
      :disabled="isDisabled"
      @input="$emit('input', $event.target.value)"
    >
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class InputText extends Vue {
  @Prop() label!: string
  @Prop() value!: string
  @Prop() isDisabled!: boolean
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables.scss";

.input-text {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  label {
    font-size: $font-size-0;
    text-transform: uppercase;
    margin-bottom: 0.4rem;
    color: $grey-2;
  }

  input {
    background: $grey-4;
    height: 4.4rem;
    border: 0;
    padding: 1rem;
    font-size: $font-size-2;
    border-bottom: 1px solid $black;
    -webkit-appearance: none;
    border-radius: 0;
  }

  &--disabled {
    input {
      color: #a6a6a6;
    }
  }
}
</style>
