<template>
  <div class="input-select">
    <label>{{label}}</label>
    <select
      :value="value"
      @input="$emit('input', $event.target.value)"
    >
      <option
        v-for="option in options"
        :key="option.id"
        :value="option.id"
      >
        {{option.label}}
      </option>
    </select>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class InputSelect extends Vue {
  @Prop() label!: string
  @Prop() value!: string
  @Prop() options!: { id: string; label: string }[]
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables.scss";

.input-select {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  label {
    font-size: $font-size-0;
    text-transform: uppercase;
    margin-bottom: 0.4rem;
    color: $grey-2;
  }

  select {
    background: $grey-4;
    height: 4.4rem;
    border: 0;
    padding: 1rem;
    font-size: $font-size-2;
    border-bottom: 1px solid $black;
    border-radius: 0;
    background: $grey-4;
    background: linear-gradient($grey-4,$grey-4);
  }
}
</style>
