<template>
  <div
    class="loading-spinner"
    :class="classNames"
  >
    <div class="loading-spinner__inner">
      <div :style="style" />
      <div :style="style" />
      <div :style="style" />
      <div :style="style" />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class LoadingSpinner extends Vue {
  @Prop({ default: '#000' }) color!: string
  @Prop({ default: true }) isFullScreen!: boolean
  @Prop({ default: false }) isSmall!: boolean

  get classNames() {
    return [
      { 'loading-spinner--fullscreen': this.isFullScreen },
      { 'loading-spinner--small': this.isSmall },
    ];
  }

  get style() {
    return {
      'border-color': `${this.color} transparent transparent transparent`,
    };
  }
}
</script>

<style lang="scss" scoped>
@keyframes loading-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-spinner {
  &__inner {
    display: inline-block;
    position: relative;
    width: 160px;
    height: 160px;

    div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: 128px;
      height: 128px;
      margin: 8px;
      border: 16px solid;
      border-radius: 50%;
      animation: loading-spinner 2s cubic-bezier(0.5, 0, 0.5, 1) infinite;

      &:nth-child(1) {
        animation-delay: -0.45s;
      }
      &:nth-child(2) {
        animation-delay: -0.3s;
      }
      &:nth-child(3) {
        animation-delay: -0.15s;
      }
    }
  }

  &--fullscreen {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &--small {
    .loading-spinner__inner {
      width: 16px;
      height: 16px;

      div {
        width: 12.8px;
        height: 12.8px;
        margin: 0;
        border: 2px solid;
      }
    }
  }
}
</style>
