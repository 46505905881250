<template>
  <div class="artist-block container-small">
    <Headline :headline="$t('artists.title')" />
    <div class="row">
      <InputSearch />
      <div>
        <p class="artist-block__counter">
          <span class="counter">{{userArtists.length}} </span>
          <span class="label">{{ userArtists.length > 1 ? $t('artists.amount') :  $t('artists.amountSingle') }}</span>
        </p>
        <Button
          @click="$emit('onArtistAll')"
          :isSmall="true"
          :isFullWidth="true"
          :text="$t('artists.all')"
        />
      </div>
    </div>
    <ul :class="{ 'artists-list-full': userArtists.length >= maxShownMobile }">
      <li
        v-for="(artist, index) in artists"
        :key="index"
      >
        <ArtistTile
          @onArtist="onArtistDelete(artist.spotify_id)"
          :name="artist.name"
          :image="artist.img_small"
          :isCloseOpen="isMobile"
        />
      </li>
    </ul>
    <Pagination
      :pagesTotal="artistPagesTotal"
      :pageCurrent="currentPage"
      @onPrev="onPrev"
      @onNext="onNext"
    />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { Getter, Mutation } from 'vuex-class';
import Headline from './Headline.vue';
import ArtistTile from './ArtistTile.vue';
import Button from './Button.vue';
import Pagination from './Pagination.vue';
import InputSearch from './InputSearch.vue';
import { Artist } from '../types';

@Component({
  components: {
    Headline,
    ArtistTile,
    Button,
    Pagination,
    InputSearch,
  },
})

export default class ArtistBlock extends Vue {
  @Mutation('SET_USER_ARTISTS') setArtists!: (artist: Artist[]) => void
  @Getter userArtists!: Artist[]
  @Getter isMobile!: boolean

  maxShownDesktop = 15
  maxShownMobile = 7

  currentPage = 1

  get maxShown(): number {
    return this.isMobile ? this.maxShownMobile : this.maxShownDesktop;
  }

  get artists(): Artist[] {
    return this.userArtists.slice(((this.currentPage - 1) * this.maxShown), (this.maxShown * this.currentPage));
  }

  get artistPagesTotal(): number {
    return Math.ceil(this.userArtists.length / this.maxShown);
  }

  onPrev() {
    this.currentPage -= 1;
  }

  onNext() {
    this.currentPage += 1;
  }

  onArtistDelete(artistId: string) {
    this.setArtists([
      ...this.userArtists.filter((a) => a.spotify_id !== artistId),
    ]);
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables.scss";
.artist-block {
  margin-bottom: 6rem;

  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    margin-bottom: 2rem;
    @media only screen and (min-width: $bp-xl) {
      margin-bottom: 6rem;
      flex-direction: row;
    }
  }
  &__counter {
    font-weight: bold;
    font-size: $font-size-3;
    margin-bottom: 1rem;
    .counter {
      color: $black;
    }
    .label {
      color: $grey-2;
    }
  }

  ul {
    display: grid;

    &.artists-list-full {
      height: 500px;

      @media only screen and (min-width: $bp-xl) {
        height: inherit;
      }
    }

    li {
      padding: 1rem 0;
      border-bottom: 1px solid $grey-3;
      @media only screen and (min-width: $bp-xl) {
        padding: 0 0 2rem 0;
      }
    }

    @media only screen and (min-width: $bp-xl) {
      gap: 2rem 1.6rem;
      grid-template-areas: ". . .";
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }
}
</style>
