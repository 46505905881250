<template>
  <div class="headline-text-block container-small">
    <Headline :headline="headline" />
    <p class="headline-text-block__text">{{text}}</p>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import Headline from './Headline.vue';

@Component({
  components: {
    Headline,
  },
})

export default class HeadlineTextBlock extends Vue {
  @Prop() headline!: string

  @Prop() text!: string
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables.scss";

.headline-text-block {
  padding-bottom: 3rem;
  border-bottom: 1px solid $grey-3;
  margin-bottom: 3rem;
  &__text {
    font-size: $font-size-3;
    color: $grey-1;
  }
}
</style>
