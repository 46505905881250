<template>
  <div class="search-wrapper">
    <div class="input-search">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="44"
        height="44"
        fill="none"
      >
        <circle cx="20" cy="20" r="5.5" stroke="#000" />
        <path d="M25 25l4.5 4.5" stroke="#000" stroke-linecap="round" />
      </svg>
      <input
        type="text"
        :placeholder="$t('artists.inputPlaceholder')"
        v-model="query"
        @input="onQuery"
      />
    </div>
    <div class="suggestion-box" :class="{ open: isOpen }">
      <ul>
        <li v-for="(artist, index) in artistsShown" :key="index">
          <ArtistTile
            @onArtist="onAddArtist(artist)"
            :name="artist.name"
            :image="artist.img_small"
            :isPlus="true"
          />
        </li>
      </ul>
    </div>
    <div
      class="suggestion-box-background"
      @click="reset"
      :class="{ open: isOpen }"
    ></div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { debounce } from 'ts-debounce';
import { Action, Getter, Mutation } from 'vuex-class';
import ArtistTile from './ArtistTile.vue';
import { Artist } from '../types';

@Component({
  components: {
    ArtistTile,
  },
})
export default class Button extends Vue {
  @Action('SEARCH_ARTISTS') searchArtists!: (p: { query: string }) => Promise<void>

  @Mutation('SET_USER_ARTISTS') setArtists!: (artist: Artist[]) => void

  @Getter userArtists!: Artist[];
  @Getter artistsSearch!: Artist[];

  query = ''

  onQuery = debounce(this.load, 500)

  get isOpen(): boolean {
    return this.query.length > 0;
  }

  get queryStripped(): string {
    return this.query.toLowerCase().trim();
  }

  get artistsShown(): Artist[] {
    return this.artistsSearch.filter((as) => !this.userArtists.find((ua) => ua.spotify_id === as.spotify_id));
  }

  reset() {
    this.query = '';
  }

  load() {
    this.searchArtists({ query: this.queryStripped });
  }

  onAddArtist(artist: Artist) {
    this.setArtists([
      artist,
      ...this.userArtists,
    ]);
    this.reset();
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables.scss";
.search-wrapper {
  position: relative;
  width: 100%;
  @media only screen and (min-width: $bp-xl) {
    width: fit-content;
    min-width: 27rem;
  }
}
.input-search {
  position: relative;
  margin-bottom: 2rem;
  z-index: 3;
  svg {
    position: absolute;
    top: 0;
    left: 0;
  }
  input {
    ::placeholder {
      color: $black;
    }
    width: 100%;
    padding-left: 4.4rem;
    padding-right: 2rem;
    font-weight: bold;
    font-size: $font-size-2;
    background: $grey-3;
    border-radius: 5rem;
    height: 4.4rem;
    border: 0;
  }
}

.suggestion-box-background {
  &.open {
    background: #00000070;
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    left: 0;
    z-index: 1;
  }
}

.suggestion-box {
  border-radius: 10px;
  background: $grey-3;
  padding: 2rem 1rem 2rem 2rem;
  margin-top: 0rem;
  position: absolute;
  display: none;
  width: 100%;
  z-index: 2;
  &.open {
    display: block;
    z-index: 2;
    min-height: 28rem;
  }
  ul {
    li {
      margin-bottom: 1rem;
    }
  }
}
</style>
