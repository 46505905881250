<template>
  <Modal
    v-if="isOpen"
    class="login-model"
    :isRounded="true"
    :isCloseable="false"
    primaryColor="#000"
    :opacity="0.8"
  >
    <Card class="login-model__card" backgroundColor="#F5F5FF" :isRounded="true">
      <Icon class="login-model__icon" iconId="spotify" :scale="3" />
      <Headline
        class="login-model__headline"
        :text="$t('loginModal.title')"
        font="Arial"
      />
      <Paragraph
        class="login-model__paragraph"
        :text="$t('loginModal.text')"
        :isFaded="true"
      />
      <div>
        <Button
          class="login-model__button"
          font="Arial"
          :isRounded="true"
          backgroundColor="#000"
          @click.native="$emit('onAccept')"
          >{{ $t("loginModal.button") }}</Button
        >
      </div>
      <Button font="Arial" :isText="true" @click.native="$emit('onDecline')">{{
        $t("loginModal.buttonCancel")
      }}</Button>
    </Card>
  </Modal>
</template>

<script lang="ts">
import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';

import Modal from 'umg-components/src/components/molecules/Modal.vue';
import Card from 'umg-components/src/components/molecules/Card.vue';
import Headline from 'umg-components/src/components/atoms/Headline.vue';
import Paragraph from 'umg-components/src/components/atoms/Paragraph.vue';
import Button from 'umg-components/src/components/atoms/Button.vue';
import Icon from 'umg-components/src/components/atoms/Icon.vue';

@Component({
  components: {
    Modal,
    Card,
    Headline,
    Paragraph,
    Button,
    Icon,
  },
})
export default class LoginModal extends Vue {
  @Prop() isOpen!: boolean

  @Watch('isOpen')
  onToggleOpen() {
    if (this.isOpen) {
      document.body.classList.add('scroll-block');
    } else {
      document.body.classList.remove('scroll-block');
    }
  }
}
</script>

<style lang="scss">
@import "../assets/scss/variables.scss";

.login-model {
  width: 630px;

  &__card {
    height: 600px !important;
  }
  &__icon {
    margin-bottom: 60px;
  }
  &__headline {
    margin-bottom: 40px !important;
  }
  &__paragraph {
    width: 280px;
    margin: 0 auto 40px;
  }
  &__button {
    margin-bottom: 16px;
    height: 62px !important;
    border-radius: 50px !important;
  }
}
</style>
