<template>
  <button
    @click="$emit('click')"
    class="button"
    :class="classNames"
  >
    <svg
      v-if="isSpotify"
      xmlns="http://www.w3.org/2000/svg"
      width="31"
      height="30"
      fill="none"
    >
      <path
        d="M15.104.158C6.864.158.188 6.894.188 15.073c0 8.24 6.676 14.916 14.915 14.916 8.18 0 14.916-6.676 14.916-14.916 0-8.18-6.736-14.915-14.915-14.915zm6.014 21.952c-.24 0-.421-.06-.602-.18-3.789-2.286-8.119-2.346-12.45-1.504-.24.06-.54.18-.72.18-.602 0-.963-.48-.963-.962 0-.601.36-.902.842-1.022 4.931-1.083 9.923-.963 14.254 1.563.36.24.54.482.54 1.023a.886.886 0 01-.901.902zm1.624-3.91c-.301 0-.542-.18-.722-.24-3.79-2.225-9.382-3.127-14.374-1.804-.3.06-.421.18-.722.18-.601 0-1.143-.541-1.143-1.203 0-.601.301-1.022.903-1.202 1.684-.482 3.428-.842 5.894-.842 3.909 0 7.698.962 10.645 2.766.48.24.722.662.722 1.143-.06.661-.542 1.203-1.203 1.203zm1.864-4.57c-.3 0-.481-.12-.782-.24-4.27-2.587-11.908-3.188-16.9-1.805-.18.06-.481.18-.782.18-.782 0-1.383-.661-1.383-1.443 0-.842.541-1.263 1.083-1.444 2.105-.6 4.45-.902 7.036-.902 4.39 0 9.022.902 12.33 2.887.48.24.781.602.781 1.323 0 .842-.661 1.444-1.383 1.444z"
        fill="#fff"
      />
    </svg>
    <LoadingSpinner
      class="button--spinner"
      :color="isDark ? '#000' : '#fff'"
      :isSmall="true"
      :isFullScreen="false"
      v-if="isLoading"
    />
    <span>
      {{text}}
    </span>
  </button>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import LoadingSpinner from './LoadingSpinner.vue';

@Component({
  components: { LoadingSpinner },
})
export default class Button extends Vue {
  @Prop() text!: string
  @Prop() isSpotify!: boolean
  @Prop() isSmall!: boolean
  @Prop() isLoading!: boolean
  @Prop() isFullWidth!: boolean
  @Prop() isDark!: boolean
  @Prop() isStatic!: boolean
  @Prop() isSecondary!: boolean

  get classNames() {
    return [
      { 'button--small': this.isSmall },
      { 'button--full-width': this.isFullWidth },
      { 'button--secondary': this.isSecondary },
      { 'button--dark': this.isDark },
      { 'button--static': this.isStatic },
    ];
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables.scss";

.button {
  background: $black;
  border-radius: 50px;
  padding: 1rem 4rem;
  font-size: $font-size-2;
  font-weight: bold;
  color: $white;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  cursor: pointer;
  position: relative;

  &--secondary {
    color: white;
    background: transparent;
    border: 1px solid white;
  }

  &--dark {
    background: $white;
    color: $black;
  }

  &--full-width {
    width: 100%;
  }

  &--small {
    padding: 0.8rem 3.5rem;
  }

  &--spinner {
    position: absolute;
    left: 16px;
    top: 12px;
  }

  &--static {
    cursor: inherit;
  }

  svg {
    width: 3rem;
    height: 3rem;
    margin-right: 1rem;
  }
}
</style>
