<template>
  <div class="footer container-small">
    <p
      class="footer__turn-off"
      v-html="$t('footer.title')"
    ></p>
    <nav>
      <ul>
        <li>
          <a
            :href="$t('footer.imprintLink')"
            target="_blank"
            rel="noopener noreferrer"
          >
            {{$t('footer.imprintTitle')}}
          </a>
        </li>
        <li>
          <a
            :href="$t('footer.privacyLink')"
            target="_blank"
            rel="noopener noreferrer"
          >
            {{$t('footer.privacyTitle')}}
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Footer extends Vue {
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables.scss";

.footer {
  margin-top: 4rem;
  border-top: 1px solid $grey-3;
  padding-top: 6rem;

  &__turn-off {
    font-size: $font-size-2;
    margin: 0 auto 6rem auto;

    &::v-deep a {
      border-bottom: 1px solid;
    }
    @media only screen and (min-width: $bp-xl) {
      max-width: 30rem;
      margin-bottom: 8rem;
    }
  }
  nav {
    padding-bottom: 4rem;
  }
  ul {
    font-size: $font-size-1;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    text-align: center;

    @media only screen and (min-width: $bp-xl) {
      flex-direction: row;
      justify-content: center;
      li {
        display: flex;
        align-items: center;
      }
    }

    li:first-child {
      &:after {
        content: "";
        display: block;
        width: 3rem;
        height: 1px;
        background: $black;
        margin: 2rem auto;
        @media only screen and (min-width: $bp-xl) {
          margin: 0 2rem;
          width: 1px;
          height: 3rem;
        }
      }
    }
  }
}
</style>
