<template>
  <div
    class="sticky-notification"
    :class="{ 'sticky-notification--open': isOpen }"
  >
    <span>{{ $t('sticky.title', { count }) }}</span>
    <div class="row">
      <Button
        :isSmall="true"
        :isSecondary="true"
        :text="$t('sticky.cancel')"
        @click="onCancel"
      />
      <Button
        :isSmall="true"
        :isDark="true"
        :isLoading="isLoading"
        :text="$t('sticky.save')"
        @click="onSave"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action, Getter, Mutation } from 'vuex-class';
import Button from './Button.vue';

@Component({
  components: {
    Button,
  },
})

export default class StickyNotification extends Vue {
  @Action('UPDATE_USER') updateUser!: (data: { language: string }) => Promise<void>
  @Mutation('RESET_USER') resetUser!: () => void

  @Prop() isOpen!: boolean

  @Getter userChangesCount!: number

  isLoading = false

  get count(): number {
    return this.userChangesCount < 0 ? -this.userChangesCount : this.userChangesCount;
  }

  onCancel() {
    this.resetUser();
  }

  async onSave() {
    this.isLoading = true;
    await this.updateUser({ language: this.$i18n.locale });
    this.isLoading = false;
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/styles.scss";

.sticky-notification {
  background: $black;
  padding: 2rem;
  position: fixed;
  bottom: -15rem;
  left: 0;
  width: 100%;
  z-index: 2;
  transition: 0.3s;

  &--open {
    bottom: 0;
  }

  @media only screen and (min-width: $bp-xl) {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  span {
    color: $white;
    font-size: $font-size-3;
    font-weight: bold;
    display: block;
    margin-bottom: 2rem;
    @media only screen and (min-width: $bp-xl) {
      margin-bottom: 0;
      margin-right: 50rem;
    }
  }

  .row {
    display: flex;

    .button:not(.button--secondary) {
      margin-left: 2rem;
    }
  }
}
</style>
