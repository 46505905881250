import { AddressBook, UserPatch } from '@/types';
import RestClient from './client';

class RestModule {
  client: RestClient
  constructor(client: RestClient) {
    this.client = client;
  }
}

export class ContactsModule extends RestModule {
  public getUrlLogin(service: string, contactId: string, email: string, redirectUrl: string) {
    const emailParam = encodeURIComponent(email);
    const redirectUrlParam = encodeURIComponent(redirectUrl);
    return this.client.getUrl(`/api/contacts/${contactId}/${emailParam}/${service}/connect?redirect_uri=${redirectUrlParam}`);
  }

  public async loadUser(contactId: string, email: string) {
    const emailParam = encodeURIComponent(email);
    return await this.client.get(`/api/contacts/${contactId}/${emailParam}`);
  }

  public addUserGenres(contactId: string, email: string, genres: string[]) {
    const emailParam = encodeURIComponent(email);
    return this.client.post(`/api/contacts/${contactId}/${emailParam}/genres`, {
      genres,
    });
  }

  public loadUserTop(contactId: string, email: string) {
    const emailParam = encodeURIComponent(email);
    return this.client.get(`/api/contacts/${contactId}/${emailParam}/top`);
  }

  public updateUser(
    contactId: string,
    email: string,
    params: UserPatch,
  ) {
    const emailParam = encodeURIComponent(email);
    return this.client.patch(`/api/contacts/${contactId}/${emailParam}`, params);
  }

  public updateUserAddressbooks(
    contactId: string,
    email: string,
    addressBooks: AddressBook[],
  ) {
    const emailParam = encodeURIComponent(email);
    return this.client.patch(`/api/contacts/${contactId}/${emailParam}`, {
      address_books: addressBooks,
    });
  }
}

export class ArtistsModule extends RestModule {
  public search(query: string) {
    return this.client.get(`/api/artists?q=${query}&limit=5`);
  }
}

export class AddressBooksModule extends RestModule {
  public get() {
    return this.client.get('/api/address_books');
  }
}

export class GeoModule extends RestModule {
  public get() {
    return this.client.get('/api/geo');
  }
}
