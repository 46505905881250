<template>
  <div
    class="artist-overlay-wrapper"
    :class="{ 'artist-overlay-wrapper--open': isOpen }"
  >
    <div class="artist-overlay">
      <div class="row">
        <Headline headline="Your top artists" />
        <svg
          @click="$emit('close')"
          class="artist-overlay__close"
          xmlns="http://www.w3.org/2000/svg"
          width="63"
          height="63"
          fill="none"
        >
          <circle
            cx="31"
            cy="31"
            r="21.5"
            transform="rotate(45 31.887 31.113)"
            stroke="#000"
          />
          <path
            d="M25.523 24.749l12.375 12.374M38.075 24.572L25.7 36.946"
            stroke="#000"
            stroke-linecap="round"
          />
        </svg>
      </div>
      <ul>
        <li v-for="(artist, index) in userArtists" :key="index">
          <ArtistTile
            @onArtist="onArtistDelete(artist.spotify_id)"
            :name="artist.name"
            :image="artist.img_small"
          />
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import { Getter, Mutation } from 'vuex-class';
import Headline from './Headline.vue';
import ArtistTile from './ArtistTile.vue';
import { Artist } from '../types';

@Component({
  components: {
    Headline,
    ArtistTile,
  },
})

export default class ArtistOverlay extends Vue {
  @Prop() isOpen!: boolean

  @Getter userArtists!: Artist[]
  @Mutation('SET_USER_ARTISTS') setArtists!: (artist: Artist[]) => void

  @Watch('isOpen')
  onToggleOpen() {
    if (this.isOpen) {
      document.body.classList.add('scroll-block');
    } else {
      document.body.classList.remove('scroll-block');
    }
  }

  onArtistDelete(artistId: string) {
    this.setArtists([
      ...this.userArtists.filter((a) => a.spotify_id !== artistId),
    ]);
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables.scss";

.artist-overlay-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  background: #00000070;
  width: 100%;
  height: 100%;
  z-index: 10;
  visibility: hidden;
  opacity: 0;
  transition: 300ms;

  &--open {
    visibility: visible;
    opacity: 1;
    transition: 300ms;
  }
}

.artist-overlay {
  background: $white;
  width: 100%;
  max-width: 90rem;
  padding: 2rem;
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
  position: fixed;
  padding-top: 10rem;
  @media only screen and (min-width: $bp-xl) {
    border-radius: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 60%;
    padding: 5rem;
  }

  &__close {
    cursor: pointer;
  }

  .row {
    display: flex;
    justify-content: space-between;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    padding: 2rem;
    background: white;
    height: 10rem;
    z-index: 2;
    @media only screen and (min-width: $bp-xl) {
      position: relative;
      background: none;
      padding: 0;
    }
  }

  ul {
    display: grid;

    li {
      padding: 1rem 0;
      border-bottom: 1px solid $grey-3;
      @media only screen and (min-width: $bp-xl) {
        padding: 0 0 2rem 0;
      }
    }

    @media only screen and (min-width: $bp-xl) {
      gap: 2rem 1.6rem;
      grid-template-areas: ". . .";
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }
}
</style>
