<template>
  <div class="pagination">
    <svg
      @click="onPrev"
      :class="{ blocked: prevIsBlocked }"
      xmlns="http://www.w3.org/2000/svg"
      width="45"
      height="44"
      fill="none"
    >
      <circle
        cx="22.5"
        cy="22"
        r="21.5"
        :stroke="prevColor"
      />
      <path
        d="M31 22H13.5m0 0l5 5m-5-5l5-5"
        :stroke="prevColor"
        stroke-linecap="round"
      />
    </svg>
    <div class="pagination__label">
      <span>{{pageCurrent}} </span>/
      <span> {{pagesTotal}}</span>
    </div>
    <svg
      @click="onNext"
      :class="{ blocked: nextIsBlocked }"
      xmlns="http://www.w3.org/2000/svg"
      style="transform:rotate(180deg)"
      height="44"
      fill="none"
    >
      <circle
        cx="22.5"
        cy="22"
        r="21.5"
        :stroke="nextColor"
      />
      <path
        d="M31 22H13.5m0 0l5 5m-5-5l5-5"
        :stroke="nextColor"
        stroke-linecap="round"
      />
    </svg>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Pagination extends Vue {
  @Prop() pagesTotal!: number
  @Prop() pageCurrent!: number

  get prevIsBlocked(): boolean {
    return this.pageCurrent === 1;
  }

  get prevColor(): string {
    return this.prevIsBlocked ? '#808080' : '#000';
  }

  get nextIsBlocked(): boolean {
    return this.pageCurrent === this.pagesTotal;
  }

  get nextColor(): string {
    return this.nextIsBlocked ? '#808080' : '#000';
  }

  onPrev() {
    if (this.prevIsBlocked) return;

    this.$emit('onPrev');
  }

  onNext() {
    if (this.nextIsBlocked) return;

    this.$emit('onNext');
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables.scss";

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  color: $grey-2;
  &__label {
    font-size: $font-size-3;
    font-weight: bold;
    margin: 0 2rem;
    span {
      color: $black;
    }
  }
  svg {
    width: 4.5rem;
    height: 4.5rem;
    cursor: pointer;

    &.blocked {
      cursor: inherit;
    }
  }
}
</style>
