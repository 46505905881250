import axios, { AxiosError } from 'axios';
import {
  ContactsModule, ArtistsModule, AddressBooksModule, GeoModule,
} from './modules';

export default class RestClient {
  public contacts: ContactsModule
  public artists: ArtistsModule
  public addressBooks: AddressBooksModule
  public geo: GeoModule

  constructor() {
    this.contacts = new ContactsModule(this);
    this.artists = new ArtistsModule(this);
    this.addressBooks = new AddressBooksModule(this);
    this.geo = new GeoModule(this);
  }

  private host = window.location.origin // same host, proxied in dev

  async getUrl(path: string) {
    return this.host + path;
  }

  async patch(path: string, data: unknown) {
    return axios.patch(this.host + path, data)
      .then((res) => res.data)
      .catch((e) => {
        throw new Error(e.response.data.error || e);
      });
  }

  async get(path: string) {
    try {
      const resp = await axios.get(this.host + path);
      return resp.data;
    } catch (e) {
      throw new Error((e as AxiosError).response?.data.error || e);
    }
  }

  async post(path: string, data: unknown) {
    return axios.post(this.host + path, data)
      .then((res) => res.data)
      .catch((e) => {
        throw new Error(e.response.data.error || e);
      });
  }
}
