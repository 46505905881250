<template>
  <label
    class="switch"
    :class="{ 'switch--language': isLanguage }"
  >
    <input
      type="checkbox"
      v-model="value"
      @input="onValue"
    />
    <span class="check">
      <div
        class="switch__language-labels"
        v-if="isLanguage"
      >
        <span
          v-for="(language, index) in languages"
          :key="index"
        >{{language.label}}</span>
      </div>
    </span>
  </label>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {
  },
})
export default class SwitchComp extends Vue {
  @Prop({ default: false }) presetValue!: boolean
  @Prop() isLanguage!: boolean
  @Prop() languages!: { id: string; label: string }[]

  value = false

  onValue() {
    if (this.isLanguage) {
      this.$emit('onLanguage', this.value ? this.languages[0].id : this.languages[1].id);
    } else {
      this.$emit('input', this.value);
    }
  }

  created() {
    this.value = this.presetValue;

    if (this.isLanguage) {
      const index = this.languages.findIndex((lang) => lang.id === this.$i18n.locale);

      this.value = index !== 0;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables.scss";

.switch {
  margin: 5px 0;
  display: flex;
  cursor: pointer;
  .label {
    font-weight: 500;
    color: $black;
    transition: all 0.2s linear;
    a {
      text-decoration: none;
      border-bottom: 1px solid $black;
      color: inherit;
      transition: all 0.2s linear;
    }
  }
  .check {
    width: 5rem;
    height: 3.6rem;
    background: $white;
    border: 1px solid $grey-2;
    display: inline-block;
    border-radius: 20px;
    position: relative;
    transition: all 0.2s linear;
    margin-right: 10px;
    &:before {
      content: "";
      position: absolute;
      height: 3.4rem;
      width: 3.4rem;
      display: block;
      border-radius: 20px;
      border: 1px solid $grey-2;
      background: white;
      box-sizing: border-box;
      transform: translate(10%, -50%);
      top: 50%;
      right: 37%;
      transition: all 0.2s linear;
    }
  }

  &__language-labels {
    position: absolute;
    z-index: 1;
    width: 9rem;
    display: flex;
    justify-content: space-around;
    font-size: $font-size-1;
    height: 34px;
    align-items: center;
    span:first-child {
      color: $white;
    }
    span:last-child {
      color: $black;
    }
  }

  input {
    display: none;
    &:checked ~ .label {
      color: $black;
      a {
        border-bottom: 1px solid $black;
      }
    }
    &:checked ~ .check {
      width: 5rem;
      height: 3.6rem;
      background: #42d36b;
      display: inline-block;
      border-radius: 20px;

      &:before {
        content: "";
        position: absolute;
        height: 3.4rem;
        width: 3.4rem;
        display: block;
        border-radius: 20px;
        background: $white;
        box-sizing: border-box;
        transform: translateY(-50%);
        top: 50%;
        right: 0;
      }
    }
  }

  &--language {
    .check {
      border: 2px solid $black;
      width: 9rem;
      &:before {
        width: 5rem;
        right: 50%;
        border-color: $black;
        background: $black;
      }
    }
    input {
      &:checked ~ .check {
        width: 9rem;
        height: 3.6rem;
        background: $white;

        .switch__language-labels {
          span:first-child {
            color: $black;
          }
          span:last-child {
            color: $white;
          }
        }

        &:before {
          content: "";
          position: absolute;
          height: 3.4rem;
          width: 5rem;
          background: $black;
        }
      }
    }
  }
}
</style>
